/**
 * @see https://osvaldas.info/caching-svg-sprite-in-localstorage
 */
export function localStorageSVGs() {

  // A part of the fallback for browsers that do not support SVG
  if (!document.createElementNS || !document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect) {
    document.createElement('svg');
    document.createElement('use');
  }

  // Storing SVG Sprite in localStorage
  // @todo remove hard coded typo3conf/ext/ url (https://stackoverflow.com/questions/1279195/how-can-code-in-a-javascript-file-get-the-files-url)
  let file = '/typo3conf/ext/ot_oliverthiele/Resources/Public/Assets/Website/Icons/Sprites.svg',
    revision = 1664282603;

  if (!document.createElementNS || !document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect)
    return true;

  let isLocalStorage = 'localStorage' in window && window['localStorage'] !== null,
    request,
    data,
    insertIT = function () {
      document.body.insertAdjacentHTML('afterbegin', data);
    },
    insert = function () {
      if (document.body) insertIT();
      else document.addEventListener('DOMContentLoaded', insertIT);
    };

  if (isLocalStorage && localStorage.getItem('inlineSVGrev') === revision) {
    data = localStorage.getItem('inlineSVGdata');
    if (data) {
      insert();
      return true;
    }
  }

  try {
    request = new XMLHttpRequest();
    request.open('GET', file, true);
    request.onload = function () {
      if (request.status >= 200 && request.status < 400) {
        data = request.responseText;
        insert();
        if (isLocalStorage) {
          localStorage.setItem('inlineSVGdata', data);
          localStorage.setItem('inlineSVGrev', revision);
        }
      }
    }
    request.send();
  } catch (e) {
  }
}
